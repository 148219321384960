<template>
  <div id="product">
    <swiper
        ref="banner"
        class="banner swiper-container"
        :options="bannerSwiperOptions"
    >
      <swiper-slide
          class="swiper-slide"
          :style="{ backgroundImage: `url(${banner})` }"
      >
        <div class="banner-title">下载中心</div>
        <div style="text-align: center;">
          <button class="button ghost banner-button">
            <i class="icon iconfont icon-xiaochengxu" />微信公众号
            <i class="icon iconfont icon-xiala1"></i>
            <img class="qrcode" src="../assets/home/qrcode.png" alt="" />
          </button>

          <button class="button ghost banner-button">
            <i class="icon iconfont icon-xiaochengxu" />微信小程序
            <i class="icon iconfont icon-xiala1"></i>
            <img class="qrcode" src="../assets/home/qrcode.png" alt="" />
          </button>

          <button class="button ghost banner-button">
            <i class="icon iconfont icon-xiaochengxu" />APP下载
            <i class="icon iconfont icon-xiala1"></i>
            <img class="qrcode" src="../assets/home/qrcode.png" alt="" />
          </button>
        </div>
      </swiper-slide>
      <!-- Add Pagination -->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

  </div>

</template>
<script>
import banner from "../assets/home/banner.png";
export default {
  data() {
    return {
      banner: banner
    }
  },
  created(){
   this.$emit('changeTab',2)
  }
}
</script>
<style lang="scss" scoped>
#product{
  height: 800px;
  width: 100%;
  background: #eceff2;
  position: relative;
}
.icon-xiaochengxu {
  margin-right: 15px;
}
.banner {
  height: 580px;
  position: relative;
  text-align: center;
.swiper-slide {
  background-size: cover;
}
::v-deep .swiper-pagination {
  position: absolute;
  width: 100%;
  bottom: 24px;
  z-index: 1;
  text-align: center;
}
::v-deep .swiper-pagination-bullet {
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  border-radius: 2px;
  width: 46px;
  height: 4px;
  margin: 0 7px;
}
::v-deep .swiper-pagination-bullet-active {
  background: var(--blue);
}
}
.banner-title {
  position: absolute;
  font-size: 54px;
  color: #ffffff;
  letter-spacing: 0;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
}
.banner-button {
  //position: absolute;
  margin-top: 350px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  padding: 13px 16px;
  //transform: translateX(-50%);
  border-radius: 5px;
  margin-right: 20px;
.icon-xiala1 {
  font-size: 16px;
  margin-left: 15px;
}
.qrcode {
  transition: all ease-out 0.3s;
  position: absolute;
  margin-top: 350px;
  width: 80%;
  //left: 50%;
  transform: translateX(-120%);
  top: 52px;
  opacity: 0;
// display: none;
  height: 100px;
  width: 100px;
}
&:hover {
.qrcode {
  opacity: 1;
}
}
}
</style>